@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;0,500;0,700;1,400;1,500&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;1,700&family=Oswald:wght@200;400;700&family=Poppins:wght@300;400;500;700&display=swap');
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

p {
  color: #7D8491;
}